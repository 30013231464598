import React, {
  FC,
  useEffect,
  useState,
  memo,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAttrVal,
  deleteBaseImgs,
  deleteBgColor,
  deleteBgImage,
  setAttrActive,
  setAttrValActive,
  setBaseImgs,
  setBaseImgsActive,
  setBgActive,
  setBgColor,
  setBgImage,
  setBgImageActive,
  addNewAttribueValue,
} from "../../../store/actions";
import { ActiveLayer } from "../../../models/constants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./style.scss";
import useUploadFile from "../../../hooks/useUploadFile";
import {
  checkAspectRatio,
  getActiveAttr,
  getActiveBaseImg,
} from "../../../utils/helper";

interface PreviewProps {
  activeLayer:
    | ActiveLayer.background
    | ActiveLayer.baseImg
    | ActiveLayer.attributes
    | "backgroundImages";
    attrValConfig?: any;
  forAttr?: string;
}

const ThumbnailPreview: FC<PreviewProps> = ({
  activeLayer,
  attrValConfig,
  forAttr,
}: PreviewProps) => {
  const [currActiveLayerData, setActiveLayerData] = useState<any>([]);
  const state = useSelector((state: any) => state.reducer);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { image, onUploadFile } = useUploadFile();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (activeLayer) {
      case ActiveLayer.background:
        getBgColorsData();
        break;
      case "backgroundImages":
        getBgImagesData();
        break;
      case ActiveLayer.baseImg:
        getBaseImagesData();
        break;
      case ActiveLayer.attributes:
        getAttrImageData();
        break;
    }
  }, [activeLayer, state]);

  useEffect(() => {
    if (image) {
      activeLayer === "backgroundImages"
        ? dispatch(
            setBgImage({
              image: image,
              active: true,
            })
          )
        : dispatch(
            setBaseImgs({
              name: "baseImg Name",
              image,
              active: true,
              attributes: [],
            })
          );
    }
  }, [image]);

  const getBgColorsData = () => {
    state.background.colors.map((item: any) => (item.showCloseIcon = false));
    setActiveLayerData(state.background.colors);
  };

  const getBgImagesData = () => {
    state.background.images.map((item: any) => (item.showCloseIcon = false));
    setActiveLayerData(state.background.images);
  };

  const getBaseImagesData = () => {
    state.baseImages.map((item: any) => (item.showCloseIcon = false));
    setActiveLayerData(state.baseImages);
  };

  const getAttrImageData = () => {
    let attrValues: any = [];
    if (forAttr) {
      let currActiveBaseImg = getActiveBaseImg(state.baseImages);
      let currActiveAttr = currActiveBaseImg?.attributes?.find(
        (item: any) => item.name === forAttr
      );
      attrValues = currActiveAttr?.values || [];
    }
    // } else {
    //   let activeAttrImages = getActiveAttr(state.baseImages).values;
    //   activeAttrImages.map((item: any) => (item.showCloseIcon = false));
    //   attrValues = activeAttrImages;
    // }
    setActiveLayerData(attrValues);
  };

  const toggleCloseIcon = (val: any) => {
    let data = currActiveLayerData;
    data.map((item: any) => {
      activeLayer === ActiveLayer.background
        ? item.color === val.color && (item.showCloseIcon = !item.showCloseIcon)
        : item.image === val.image &&
          (item.showCloseIcon = !item.showCloseIcon);
    });
    setActiveLayerData([...data]);
  };

  const removeThumbnail = (event: React.MouseEvent, item: any) => {
    event.stopPropagation();

    switch (activeLayer) {
      case ActiveLayer.background:
        dispatch(deleteBgColor(item));
        break;
      case ActiveLayer.baseImg:
        dispatch(deleteBaseImgs(item));
        break;
      case "backgroundImages":
        dispatch(deleteBgImage(item));
        break;
      case ActiveLayer.attributes:
        dispatch(deleteAttrVal({forAttr: forAttr || '' ,attrVal:item}));
        break;
    }
  };

  const setActiveThumbnail = (item: any) => {
    switch (activeLayer) {
      case ActiveLayer.background:
        dispatch(setBgActive(item));
        break;
      case ActiveLayer.baseImg:
        dispatch(setBaseImgsActive(item));
        break;
      case "backgroundImages":
        dispatch(setBgImageActive(item));
        break;
      case ActiveLayer.attributes:
        dispatch(setAttrValActive({forAttr: forAttr || '',attrVal:item}));
        break;
    }
  };

  const addThumbnail = () => {
    activeLayer === ActiveLayer.background
      ? dispatch(setBgColor(state.background.colors.slice(-1)[0]))
      : inputFileRef.current?.click();
  };

  const uploadNewAtrrVal = (event: any) => {
    checkAspectRatio(event?.target?.files[0], attrValConfig)
      .then((res) =>
      {console.warn({
        forAttr: forAttr || '',
        attrVal: {
          image: res,
          imgConfig: attrValConfig,
          active: true,
        },
      });
        dispatch(
          addNewAttribueValue({
            forAttr: forAttr || '',
            attrVal: {
              image: res,
              imgConfig: attrValConfig,
              active: true,
            },
          })
        )}
      )
      .catch((err) => alert(err));
  };

  return (
    <>
      <div
        className={`previews flex justify-start gap-4 items-center flex-wrap ${
          activeLayer != "backgroundImages" && "mb-4"
        }`}
      >
        {currActiveLayerData.map((item: any, index: number) => (
          <div
            className={`rounded cursor-pointer relative ${
              item.active && "active"
            }`}
            key={index}
            onMouseEnter={() => toggleCloseIcon(item)}
            onMouseLeave={() => toggleCloseIcon(item)}
            style={
              activeLayer === ActiveLayer.background
                ? { background: item.color }
                : {}
            }
            onClick={() => setActiveThumbnail(item)}
          >
            {item.showCloseIcon && index != 0 && (
              <CancelOutlinedIcon
                className="cancelIcon"
                onClick={(event: React.MouseEvent) =>
                  removeThumbnail(event, item)
                }
              />
            )}
            {(activeLayer === ActiveLayer.baseImg || "backgroundImages") &&
              item.image && (
                <img className="rounded" src={`${item.image}`} alt="default" />
              )}
          </div>
        ))}
        <input
          type="file"
          accept="image/*"
          ref={inputFileRef}
          style={{ display: "none" }}
          onChange={(event: any) => {
            activeLayer === ActiveLayer.attributes
              ? uploadNewAtrrVal(event)
              : onUploadFile(event);
          }}
        />
        <div className="plusIcon" onClick={addThumbnail}>
          {<AddOutlinedIcon />}
        </div>
      </div>
    </>
  );
};
export default memo(ThumbnailPreview);
