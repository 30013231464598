import Button from "@mui/material/Button";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActiveLayer } from "../../../models/constants";
// import { setBaseImgAttributes } from "../../../store/actions";
import {
  checkAspectRatio,
  downloadCroppedImage,
  getActiveAttr,
  getActiveBaseImg,
} from "../../../utils/helper";
import ThumbnailPreview from "../ThumbnailPreview";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./style.scss";

const ImageLayerPreview:FC<{layerName:string}> = ({layerName}) => {
  const state = useSelector((state: any) => state.reducer);
  const [activeImg, setActiveImg] = useState<any>({ image: "" });

  useEffect(() => {
    switch (state.activeLayer) {
      case ActiveLayer.baseImg:
        setActiveImg(getActiveBaseImg(state.baseImages));
        break;
      case ActiveLayer.attributes:
        setActiveAttrImage();
        break;
    }
  }, [state, state.activeLayer]);

  const setActiveAttrImage = () => {
    let activeAttrImages = getActiveAttr(state.baseImages).values;
    setActiveImg(activeAttrImages.find((item: any) => item.active));
  };

  return (
    <div className="imgPreview">
      {activeImg && activeImg.image ? (
        <div className="text-center">
          <img className="imgPreview rounded" src={activeImg.image} />
          {activeImg.imgConfig && (
             <Button
             variant="contained"
             className="w-[40px] h-[40px] bg-mainBgColor rounded-lg my-4"
             onClick={() => downloadCroppedImage(activeImg.image)}
           >
             <FileDownloadOutlinedIcon />
           </Button>
            // <input
            //   type="file"
            //   onChange={(e: React.ChangeEvent<any>) =>
            //     checkAspectRatio(e.target.files[0], activeImg.imgConfig)
            //       .then((res: any) =>
            //         updateBaseImgAttributes({
            //           image: res,
            //           imgConfig: activeImg.imgConfig,
            //           active: true,
            //         })
            //       )
            //       .catch((e) =>
            //         alert("PLEASE check the aspect ratio of uploaded image")
            //       )
            //   }
            // />
          )}
        </div>
      ) : (
        <p className="text-center mt-4 text-gray-400">No Image was selected</p>
      )}
      <br />
      <p className="text-md font-medium mb-4">Preview Selected Images :</p>

      <ThumbnailPreview activeLayer={state.activeLayer} attrValConfig = {activeImg?.imgConfig || null} forAttr={layerName} />
    </div>
  );
};

export default ImageLayerPreview;
