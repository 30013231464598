import { combineReducers, compose, createStore } from "redux";
import { RootReducer } from "./reducers";

// redux devtools
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// combine reducers
const allReducers = combineReducers({
    reducer:RootReducer
})

// middlewares
const composeEnhancers =
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appStore = createStore(allReducers, composeEnhancers());

export {allReducers,appStore};