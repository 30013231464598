import Workspace from "../components/Workspace/index";
import LayerPreview from "../components/LayerPreview/index";
import Layers from "../components/Layers/index";

function HomePage() {
  return (
    <div className="flex">
      <Layers />
      <Workspace />
      <LayerPreview/>
    </div>
  );
}

export default HomePage;
