import { useState, useEffect, forwardRef } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { setBgColor } from "../../store/actions";

function ColorPicker() {
  const [colors, setColor] = useState<any>({color:''});
  const appState = useSelector((state: any) => state.reducer);
  const dispatch = useDispatch();

  const handleColorChange = (color: any) => {
    // !colorAlreayExists(color.hex) &&
    setColor({
      color: color.hex
    });
  };

  useEffect(() => {
    colors.color && dispatch(setBgColor(colors));
  }, [colors]);

  const colorAlreayExists = (color: string): boolean => appState.bgColors.some((item: any) => item.color === color);

  return (
    <div>
      <SketchPicker
        color={colors?.color}
        onChangeComplete={handleColorChange}
      />
    </div>
  );
}

export default ColorPicker;
