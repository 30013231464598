import { RootState } from "./../models/index";
import { Attribute, BaseImgType } from "../models";

function getCroppedImg(sourceImage: any, cropConfig: any, fileName: any) {
  // creating the cropped image from the source image
  const canvas = document.createElement("canvas");
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = cropConfig.width;
  canvas.height = cropConfig.height;
  const ctx = canvas.getContext("2d");
  ctx &&
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      // returning an error
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }

      blob.name = fileName;
      // creating a Object URL representing the Blob object given
      const croppedImageUrl = window.URL.createObjectURL(blob);

      resolve(croppedImageUrl);
    }, "image/png");
  });
}

async function downloadCroppedImage(imageSrc: any) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "Cropped Image";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function checkAspectRatio(value: any, aspect: any) {
console.log(value)
  const croppedImgWidth = Math.floor(aspect.width),
    croppedImgHeight = Math.floor(aspect.height);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(value);

    reader.onload = function (value) {
      const img: any = new Image();
      console.log("image is ",value)
      value?.target && (img.src = value.target.result);
      img.onload = function () {
        const aspectRatio = this.width / this.height;
        const croppedImgRatio = croppedImgWidth / croppedImgHeight;
        aspectRatio === croppedImgRatio ? resolve(img.src) : reject("Please check the dimensions");
      };
    };
  });
}

function getActiveBaseImg(baseImages: BaseImgType[]): BaseImgType {
  return (
    baseImages.find((item: any) => item.active) || {
      name: "",
      image: "",
      attributes: [],
    }
  );
}

function getActiveAttr(baseImages: BaseImgType[]): Attribute {
  let currActiveBaseImg = getActiveBaseImg(baseImages);
  let currActiveAttr = currActiveBaseImg?.attributes?.find(
    (item: any) => item.active
  );
  return currActiveAttr || { name: "", values: [] };
}

function getActiveBgColor(state: RootState) {
  return state.background.colors.find((item: any) => item.active)?.color || "";
}
function getActiveBgImage(state: RootState) {
  return (
    state.background.images.find((item: any) => item.active) || { image: "" }
  );
}

export {
  getCroppedImg,
  downloadCroppedImage,
  checkAspectRatio,
  getActiveBaseImg,
  getActiveAttr,
  getActiveBgColor,
  getActiveBgImage,
};
