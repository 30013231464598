import React, { useState } from 'react'

function useUploadFile() {
    const [image,setImage] = useState<any>();
    const onUploadFile = (event:React.ChangeEvent<any>) => {
        if (event.target.files && event.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            const image = reader.result;
            setImage(image);
          });
          reader.readAsDataURL(event.target.files[0]);
        }
      };
    return {image,onUploadFile}
}

export default useUploadFile
