import { useState, useEffect, FC, useRef } from "react";
import {
  downloadCroppedImage,
  checkAspectRatio,
  getActiveAttr,
  getActiveBaseImg,
} from "../../utils/helper";

import { useDispatch, useSelector } from "react-redux";
import { BaseImgType } from "../../models";
import { layersData } from "../Layers";
import { ActiveLayer } from "../../models/constants";
import { setBgImage } from "../../store/actions";
import ColorPicker from "./ColorPicker";
import ThumbnailPreview from "../common/ThumbnailPreview";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useUploadFile from "../../hooks/useUploadFile";
import Button from "@mui/material/Button";
import "./style.scss";
import ImageLayerPreview from "../common/ImageLayerPreview";

const LayerPreview: FC = () => {
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [croppedImgConfig, setCroppedImgConfig] = useState(null);
  const [activeLayer, setActiveLayer] = useState("");
  var appState = useSelector((state: any) => state.reducer);

  useEffect(() => {
    setActiveLayer(appState.activeLayer);
    appState.activeLayer === ActiveLayer.attributes &&
      getActiveAttrDetails(appState.baseImages);
  }, [appState, appState.activeLayer]);

  function getActiveAttrDetails(baseImages: BaseImgType[]) {
    let currActiveAttr = getActiveAttr(baseImages);
    currActiveAttr?.values.map((item: any) => {
      if (item.active) {
        setCroppedImage(item.image);
        setCroppedImgConfig(item.imgConfig);
      }
    });
  }

  function getLayerName(): string {
    return activeLayer === ActiveLayer.attributes
      ? getActiveAttr(appState.baseImages).name
      : layersData.find((item: any) => item.value === appState.activeLayer)
          ?.name || "";
  }

  return (
    <div className="layerPreview px-5 pt-[20px]">
      <h2 className="title">Layer Preview</h2>
      <p className="field">{getLayerName()}</p>
      {
        {
          [ActiveLayer.attributes]: (
            <ImageLayerPreview layerName = {getLayerName()} />
            // <AttributePreview config={{ croppedImage, croppedImgConfig }} />
          ),
          [ActiveLayer.background]: <BgLayerPreview />,
          [ActiveLayer.baseImg]: <ImageLayerPreview layerName = {getLayerName()} />,
        }[activeLayer]
      }
    </div>
  );
};

const AttributePreview = (props: any) => {
  let { croppedImage, croppedImgConfig } = props.config;
  return (
    <>
      {croppedImage && (
        <section className="text-center mb-2">
          <img
            alt="Cropped Img"
            className="mb-4 mx-[auto]"
            src={croppedImage}
          />
          <Button
            variant="contained"
            className="w-[40px] h-[40px] bg-mainBgColor rounded-lg my-4"
            onClick={() => downloadCroppedImage(croppedImage)}
          >
            <FileDownloadOutlinedIcon />
          </Button>
        </section>
      )}

      <section>
        {/* <h2 className="title">New Edit Image</h2>
      {layer && <img src={layer} />} */}
        {/* <input
        type="file"
        onChange={(e: React.ChangeEvent<any>) =>
          checkAspectRatio(e.target.files[0], croppedImgConfig)
            .then((res: any) => setLayer(res))
            .catch((e) =>
              alert("PLEASE check the aspect ratio of uploaded image")
            )
        }
      /> */}
      </section>
    </>
  );
};

const BgLayerPreview = () => {
  return (
    <>
      <ColorPicker />
      <br></br>
      <p className="text-md font-medium mb-4">Preview Selected Colors :</p>
      <ThumbnailPreview activeLayer={ActiveLayer.background} />
      <p className="orDivider text-sm font-medium relative text-center">OR</p>
      <p className="text-md font-medium mb-4 mt-4">
        Preview Background Images :{" "}
      </p>
      <div className="flex justify-start gap-4 items-center flex-wrap mb-4">
        <ThumbnailPreview activeLayer={"backgroundImages"} />
      </div>
    </>
  );
};

export default LayerPreview;
