import { Provider } from "react-redux";
import "./App.scss";
import HomePage from "./pages/HomePage";
import {appStore} from "./store";



function App() {
 
  return (
    <Provider store={appStore}>
      <HomePage />
    </Provider>
  );
}

export default App;
