import { Attribute, BgColorType, BgImageType } from "./../../models/index";
import { AttrValType, BaseImgType } from "../../models";
import { ActiveLayer } from "../../models/constants";

// For Background
const setBgColor = (val: BgColorType) => {
  return { type: ActionItems.setBgColor, payload: val };
};

const deleteBgColor = (val: BgColorType) => {
  return { type: ActionItems.deleteBgColor, payload: val };
};

const setBgActive = (val: BgColorType) => {
  return { type: ActionItems.setBgColorActive, payload: val };
};

const setBgImage = (val: BgImageType) => {
  return { type: ActionItems.setBgImage, payload: val };
};

const deleteBgImage = (val: BgImageType) => {
  return { type: ActionItems.deleteBgImage, payload: val };
};

const setBgImageActive = (val: BgImageType) => {
  return { type: ActionItems.setBgImageActive, payload: val };
};

// For Active Layer
const updateLayer = (
  val:
    | ActiveLayer.emptyCanvas
    | ActiveLayer.background
    | ActiveLayer.baseImg
    | ActiveLayer.attributes
) => {
  return { type: ActionItems.setActiveLayer, payload: val };
};

// For Base Images
const setBaseImgs = (baseImg: BaseImgType) => {
  return { type: ActionItems.setBaseImg, payload: baseImg };
};

const deleteBaseImgs = (val: BaseImgType) => {
  return { type: ActionItems.deleteBaseImage, payload: val };
};

const setBaseImgsActive = (val: BaseImgType) => {
  return { type: ActionItems.setBaseImgActive, payload: val };
};

// For Base Image Attributes

const addNewAttribute = (attr: Attribute) => {
  return { type: ActionItems.addAttribute, payload: attr };
};

const addNewAttribueValue = (val: {
  forAttr: string;
  attrVal: AttrValType;
}) => {
  return { type: ActionItems.addNewAttrVal, payload: val };
};

const addNewValueForActiveAttr = (val: AttrValType) => {
  return { type: ActionItems.addNewAttrValForActive, payload: val };
};

const deleteAttrVal = (val: { forAttr: string; attrVal: AttrValType }) => {
  return { type: ActionItems.deleteAttrVal, payload: val };
};

const setAttrActive = (val: any) => {
  return { type: ActionItems.setAttributeActive, payload: val };
};

const setAttrValActive = (val: { forAttr: string; attrVal: AttrValType }) => {
  return { type: ActionItems.setAttrValActive, payload: val };
};

enum ActionItems {
  setBaseImg = "SET_BASE_IMAGE",
  deleteBaseImage = "DELETE_BASE_IMAGE",
  setBaseImgActive = "SET_BASE_IMG_ACTIVE",
  addNewAttrValForActive = "ADD_NEW_ATTR_VAL_FOR_ACTIVE",
  addNewAttrVal = "ADD_NEW_ATTR_VAL",
  setActiveLayer = "SET_ACTIVE_LAYER",
  addAttribute = "ADD_NEW_ATTRIBUTE",
  setAttributeActive = "SET_ATTR_ACTIVE",
  setBgColor = "SET_BG_COLOR",
  deleteBgColor = "DELETE_BG_COLOR",
  setBgColorActive = "SET_BG_COLOR_ACTIVE",
  setBgImage = "SET_BG_IMAGE",
  deleteBgImage = "DELTE_BG_IMAGE",
  setBgImageActive = "SET_BG_IMAGE_ACTIVE",
  setAttrValActive = "SET_ATTR_VAL_ACTIVE",
  deleteAttrVal = "DELETE_ATTR_VAL",
  addNewValueForActiveAttr = "addNewValueForActiveAttr",
}

export {
  setBaseImgs,
  addNewAttribueValue,
  addNewValueForActiveAttr,
  updateLayer,
  addNewAttribute,
  setAttrActive,
  setBgColor,
  deleteBgColor,
  setBgActive,
  deleteBaseImgs,
  setBaseImgsActive,
  setBgImage,
  deleteBgImage,
  setBgImageActive,
  setAttrValActive,
  deleteAttrVal,
  ActionItems,
};
