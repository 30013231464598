import { FC, useState, useEffect, useRef } from "react";
import "./style.scss";
import { Attribute, Layer } from "../../models/index";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAttribute,
  setAttrActive,
  updateLayer,
} from "../../store/actions";
import { getActiveBaseImg } from "../../utils/helper";
import ThumbnailPreview from "../common/ThumbnailPreview";
import { AccessKeysInStore, ActiveLayer } from "../../models/constants";
import { Divider } from "@mui/material";

const Layers: FC = () => {
  const [attributes, setAttributes] = useState<any>([]);
  const dispatch = useDispatch();
  const inputRef = useRef<any>();
  const appState = useSelector((state: any) => state.reducer);

  const addAttribute = () => {
    !attrAlreadyExists() && inputRef.current.value
      ? dispatch(
          addNewAttribute({
            name: inputRef.current.value,
            active: true,
            values: [],
          })
        )
      : alert("Already Exists");

    inputRef.current.value = "";
  };

  const attrAlreadyExists = (): boolean => {
    let currActiveBase = getActiveBaseImg(appState.baseImages);
    return currActiveBase.attributes.some(
      (item: any) => item.name === inputRef.current.value
    );
  };

  useEffect(() => {
    let currActiveBase = getActiveBaseImg(appState.baseImages);
    setAttributes(currActiveBase.attributes);
  }, [appState]);

  return (
    <div className="layersContainer px-5 pt-[20px]">
      <h2 className="title"> Layers </h2>
      <div className="layers mb-4">
        {layersData?.length &&
          layersData.map((item: Layer, index: number) => (
            <span key={index}>
              <div
                className={`field mb-4 ${
                  appState.activeLayer === item.value && "activeLayer"
                }`}
                onClick={() => dispatch(updateLayer(item.value))}
              >
                {item.name}
              </div>
              { item.value != ActiveLayer.emptyCanvas  &&(
                <div className = "px-2">
                <ThumbnailPreview activeLayer = {item.value} />
                {item.value === ActiveLayer.background && 
                <div className="mb-4">
                  <p className = "text-white text-xs mb-2" >Preview Images :</p>
                  <ThumbnailPreview activeLayer = {"backgroundImages"} />
                </div>}
                </div>
              )}
            </span>
          ))}
          
        <h2 className="title mt-10"> Attributes </h2>
        {attributes.map((item: Attribute, index: number) => (
          <span  key={index}>
          <div
            className={`field ${appState.activeLayer === ActiveLayer.attributes && item.active && "activeLayer"}`}
           
            onClick={() => dispatch(setAttrActive(item.name))}
          >
            {item.name}
          </div>
          <ThumbnailPreview activeLayer = {ActiveLayer.attributes} attrValConfig = {item?.values.length ? item.values[0].imgConfig : null} forAttr = {item.name}/>
          </span>
        ))}
        <div
          className="field flex justify-between gap-2 px-2 py-2 items-center"
          style={{ justifyContent: "space-between" }}
        >
          <input ref={inputRef} type="text" placeholder="New Layer" />
          <p className="addAttr" onClick={addAttribute}>+</p>
        </div>
      </div>
      <Button variant="contained" className="w-full h-[40px] bg-mainBgColor">
        Preview
      </Button>
    </div>
  );
};


export default Layers;

export const layersData: Layer[] = [
  {
    id: 0,
    name: "Empty Canvas",
    value: ActiveLayer.emptyCanvas,
    // accessKeysInStore: undefined,
  },
  {
    id: 1,
    name: "Background *",
    value: ActiveLayer.background,
    // accessKeysInStore: AccessKeysInStore.bgColors,
  },
  {
    id: 2,
    name: "Base Image *",
    value: ActiveLayer.baseImg,
    // accessKeysInStore:  AccessKeysInStore.baseImages,
  },
];
