import { ActiveLayer } from "../../models/constants";
import { getActiveAttr, getActiveBaseImg } from "../../utils/helper";
import { ActionItems } from "../actions";
import {
  Attribute,
  AttrValType,
  BaseImgType,
  RootState,
} from "./../../models/index";

const initState: RootState = {
  background: {
    colors: [{ color: "transparent", active: true }],
    images: [{ image: "", active: false }],
  },
  activeLayer: ActiveLayer.emptyCanvas,
  baseImages: [
    { name: "defaultBaseImg", image: "", active: true, attributes: [] },
  ],
};

export const RootReducer = (state = initState, action: any) => {
  switch (action.type) {
    // For Layer
    case ActionItems.setActiveLayer:
      return { ...state, activeLayer: action.payload };
      
    // For Background
    case ActionItems.setBgColor:
    case ActionItems.setBgImage:
      return addBackground(action.payload, state, action.type);
    case ActionItems.deleteBgColor:
    case ActionItems.deleteBgImage:
      return deleteBackground(action.payload, state, action.type);
    case ActionItems.setBgColorActive:
    case ActionItems.setBgImageActive:
      return setBackgroundActive(action.payload, state, action.type);

    // For Base Images
    case ActionItems.setBaseImg:
      return setBaseImgs(action.payload, state);
    case ActionItems.deleteBaseImage:
      return deleteBaseImgs(action.payload, state, action.type);
    case ActionItems.setBaseImgActive:
      return setBaseImageActive(action.payload, state);

    // For Attributes
    case ActionItems.addAttribute:
      return addNewAttribute(action.payload, state);
    case ActionItems.addNewAttrVal:
      return addNewAttribueValue(action.payload, state);
    case ActionItems.addNewAttrValForActive:
      return addNewAttrValForActive(action.payload, state);
    case ActionItems.setAttributeActive:
      return setActiveAttr(action.payload, state);
    case ActionItems.setAttrValActive:
      return setAttrValActive(action.payload, state);
    case ActionItems.deleteAttrVal:
      return deleteAttrValue(action.payload, state);

    default:
      return state;
  }
};

// operatios for background
const addBackground = (
  val: any,
  state: RootState,
  forType: ActionItems.setBgColor | ActionItems.setBgImage
) => {
  let key: "colors" | "images";
  forType === ActionItems.setBgColor ? (key = "colors") : (key = "images");
  state.background["colors"].map((item: any) => {
    item.active = false;
    delete item["showCloseIcon"];
  });
  state.background["images"].map((item: any) => {
    item.active = false;
    delete item["showCloseIcon"];
  });
  key === "colors"
    ? (state.background[key] = [
        ...state.background[key],
        { color: val.color, active: true },
      ])
    : (state.background[key] = [
        ...state.background[key],
        { image: val.image, active: true },
      ]);
  return { ...state };
};

const deleteBackground = (
  val: any,
  state: RootState,
  forType: ActionItems.deleteBgColor | ActionItems.deleteBgImage
) => {
  let key: "colors" | "images";
  forType === ActionItems.deleteBgColor ? (key = "colors") : (key = "images");

  let index = state.background[key].indexOf(val);
  state.background[key].splice(index, 1);

  if (val.active) {
    state.background[key].map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });
    state.background[key][index - 1].active = true;
  }

  return { ...state };
};

const setBackgroundActive = (
  val: any,
  state: RootState,
  forType: ActionItems.setBgColorActive | ActionItems.setBgImageActive
) => {
  let key: "colors" | "images";
  forType === ActionItems.setBgColorActive
    ? (key = "colors")
    : (key = "images");

  state.background["images"].map((item: any) => {
    item.active = false;
    delete item["showCloseIcon"];
  });
  state.background["colors"].map((item: any) => {
    item.active = false;
    delete item["showCloseIcon"];
  });
  state.background[key].map((item: any) => {
    key === "colors"
      ? item.color === val.color && (item.active = true)
      : item.image === val.image && (item.active = true);
  });
  return { ...state };
};

// operations for base image
const setBaseImgs = (val: BaseImgType, state: RootState) => {
  let currState: any = state;
  currState.baseImages.map((item: any) => (item.active = false));
  currState.baseImages = currState.baseImages.concat(val);
  state.baseImages = currState.baseImages;
  return { ...state };
};

const deleteBaseImgs = (
  val: BaseImgType | AttrValType,
  state: RootState,
  forType: ActionItems.deleteAttrVal | ActionItems.deleteBaseImage
) => {
  let attrValues: AttrValType[] = getActiveAttr(state.baseImages).values;
  const data: any =
    forType === ActionItems.deleteAttrVal ? attrValues : state["baseImages"];

  let index = data.indexOf(val);

  data.splice(index, 1);
  if (val.active) {
    data.map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });
    data[index - 1].active = true;
  }
  return { ...state };
};

const setBaseImageActive = (val: BaseImgType, state: RootState) => {
  state["baseImages"].map((item: any) => {
    item.active = false;
    delete item["showCloseIcon"];
  });
  state["baseImages"].map(
    (item: BaseImgType) => item.image === val.image && (item.active = true)
  );
  return { ...state };
};

// operations for attributes
const addNewAttrValForActive = (val: AttrValType, state: RootState) => {
  console.log("called");
  let currActiveAttr = getActiveAttr(state.baseImages);
  if (currActiveAttr) {
    currActiveAttr.values.map((item: any) => {
      item.active = false;
    });
    console.log(currActiveAttr);
    currActiveAttr.values.length
      ? (currActiveAttr.values = [...currActiveAttr.values, val])
      : (currActiveAttr.values = [val]);
  }
  console.log(currActiveAttr);
  state.baseImages.find((item: any) => {
    item.active &&
      item.attributes.find(
        (attr: any) => attr.active && (attr = currActiveAttr)
      );
  });

  return { ...state };
};

const addNewAttribute = (val: any, state: RootState) => {
  state.activeLayer = ActiveLayer.attributes;
  state.baseImages.find((item: any) => {
    if (item.active) {
      item.attributes.map((attr: any) => (attr.active = false));
      item.attributes = [...item.attributes, val];
    }
  });
  return { ...state };
};

const setActiveAttr = (val: string, state: RootState) => {
  state.activeLayer = ActiveLayer.attributes;
  state.baseImages.find((item: any) => {
    if (item.active) {
      item.attributes.map((attr: any) => {
        attr.active = false;
        attr.name === val && (attr.active = true);
      });
    }
  });

  return { ...state };
};

const addNewAttribueValue = (
  val: { forAttr: string; attrVal: AttrValType },
  state: RootState
) => {
  let { forAttr, attrVal } = val;
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  activeBaseImg.attributes.map((attr: Attribute) => {
    attr.name === forAttr &&
      attr.values.map((item: any) => (item.active = false)) &&
      attr.values.push(attrVal);
  });
  return { ...state };
};

const setAttrValActive = (
  val: { forAttr: string; attrVal: AttrValType },
  state: RootState
) => {
  let { forAttr, attrVal } = val;
  let attribute = getActiveBaseImg(state.baseImages).attributes.find(
    (item: Attribute) => item.name === forAttr
  );

  attribute?.values.map((item: AttrValType) => {
    item.active = false;
    item.image === attrVal.image && (item.active = true);
  });
  state.baseImages.find((item: any) => {
    item.active &&
      item.attributes.find(
        (attr: any) => attr.name === forAttr && (attr = attribute)
      );
  });

  return { ...state };
};

const deleteAttrValue = (
  val: { forAttr: string; attrVal: AttrValType },
  state: RootState
) => {
  let { forAttr, attrVal } = val;
  let attribute = getActiveBaseImg(state.baseImages).attributes.find(
    (item: Attribute) => item.name === forAttr
  );
  let index = attribute?.values.indexOf(attrVal);
  index && attribute?.values.splice(index, 1);
  if (attrVal.active) {
    attribute?.values.map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });
    index &&
      attribute?.values.length &&
      (attribute.values[index - 1].active = true);
  }
 
  state.baseImages.find((item: any) => {
    item.active &&
      item.attributes.find(
        (attr: any) => attr.name === forAttr && (attr = attribute)
      );
  });

  return { ...state };
};
