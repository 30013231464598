import { useState, useEffect } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import {
  checkAspectRatio,
  getActiveAttr,
  getActiveBaseImg,
  getActiveBgColor,
  getActiveBgImage,
  getCroppedImg,
} from "../../utils/helper";
import "react-image-crop/dist/ReactCrop.css";
// import GridContainer from "./GridContainer";
import "./style.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBaseImgs, addNewValueForActiveAttr } from "../../store/actions";
import { AttrValType } from "../../models";

// interface TransparentImagesType {
//   bgImage: ImageType;
//   baseImage: ImageType;
//   attributeImages: ImageType[];
// }
interface TransparentImagesType {
  image: any;
  imageConfig?: any;
}

const Cropper = ({ baseImg }: any) => {
  const initCropConfig: Crop = {
    unit: "px", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 0,
    height: 0,
  };
  const dispatch = useDispatch();
  const appState = useSelector((state: any) => state.reducer);
  const [cropConfig, setCropConfig] = useState(initCropConfig);
  const [baseImgRef, setBaseImgRef] = useState();

  const [activeBgColor, setActiveBgColor] = useState("");
  const [activeBgImage, setActiveBgImage] = useState({ image: "" });
  const [transparentImages, setTransparentImages] = useState<
    TransparentImagesType[]
  >([]);

  useEffect(() => {
    // Whenever a new base image is uploaded change the state
    baseImg &&
      dispatch(
        setBaseImgs({
          name: "baseImg Name",
          image: baseImg,
          active: true,
          attributes: [],
        })
      );
  }, [baseImg]);

  useEffect(() => {
    // Set background color
    setActiveBgColor(getActiveBgColor(appState));
    setActiveBgImage(getActiveBgImage(appState).image);
    //set transparent images
    getAllTransparentLayeredImages();
  }, [appState]);

  useEffect(() => {
    // when base image changes reset crop
    setCropConfig(initCropConfig);
  }, [transparentImages, transparentImages[1]]);

  const getAllTransparentLayeredImages = () => {
    let transparentImages: Array<TransparentImagesType> = [];
    // let activeBgImage = getActiveBgImage(appState);
    let activeBase = getActiveBaseImg(appState.baseImages);
    let attrLayers: any[] = [];
    activeBase.attributes.map((attr: any) => {
      attrLayers.push(
        attr.values.find((item: any) => item.active) || { image: "" }
      );
    });
    // activeBgImage && (transparentImages[0] = {image: activeBgImage.image})
    console.log(attrLayers);
    attrLayers?.length &&
      attrLayers.map((item: any) => transparentImages.push(item));

    setTransparentImages(transparentImages);
  };

  async function cropImg(crop: any) {
    console.log(crop.width, crop.height);
    if (baseImgRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        baseImgRef,
        crop,
        "croppedImg.jpeg"
      );
      dispatch(
        addNewValueForActiveAttr({
          image: croppedImage,
          imgConfig: crop,
          active: true,
        })
      );
    }
  }

  return (
    <>
      <ReactCrop
        crop={cropConfig}
        src={getActiveBaseImg(appState.baseImages).image || null}
        onChange={(crop: any) => {
          setCropConfig(crop);
          // setresized((prev) => !prev);
        }}
        disabled={
          getActiveAttr(appState.baseImages).values.length ? true : false
        }
        onImageLoaded={(imgRef: any) => {
          setBaseImgRef(imgRef);
          setCropConfig(initCropConfig);
        }}
        onComplete={(cropConfig) => cropImg(cropConfig)}
        crossorigin="anonymous"
        style={
          activeBgImage
            ? { backgroundImage: `url(${activeBgImage})`}
            : { background: activeBgColor }
        }
      >
        {/* <GridContainer cropConfig={cropConfig} setCropConfig={setCropConfig} /> */}

        {transparentImages.length &&
          transparentImages.map(
            (item: any, index: number) =>
              item.image && (
                <div
                  className="relative bg-transparent"
                  style={{
                    width: "400px",
                    height: "400px",
                    position: "absolute",
                    pointerEvents: "none",
                    left: "0",
                    top: "0 ",
                  }}
                  key={index}
                >
                  <img
                    src={item.image}
                    style={
                      item.imgConfig && {
                        width: `${item.imgConfig.width}px`,
                        height: `${item.imgConfig.height}px`,
                        position: "absolute",
                        left: `${item.imgConfig.x}px`,
                        top: `${item.imgConfig.y}px`,
                        border: `${
                          index === transparentImages.length - 1
                            ? ""
                            : "1px dashed #ff6f26"
                        }`,
                      }
                    }
                  />
                </div>
              )
          )}
      </ReactCrop>

      {/* <input
        type="file"
        onChange={(e: React.ChangeEvent<any>) =>
          checkAspectRatio(e.target.files[0], cropConfig)
            .then((res) => createAndSetTopLayer(res))
            .catch((e) =>
              alert("PLEASE check the aspect ratio of uploaded image")
            )
        }
      /> */}
    </>
  );
};

export default Cropper;
