enum ActiveLayer {
  emptyCanvas = "emptyCanvas",
  background = "background",
  baseImg = "baseImg",
  attributes = "attributes",
}

enum AccessKeysInStore {
  bgColors = "bgColors",
  baseImages = "baseImages",
}

export { ActiveLayer, AccessKeysInStore };
