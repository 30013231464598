import React from "react";
import { useState } from "react";
import Cropper from "./Cropper";

function Workspace() {
  const [baseImg, setBaseImg] = useState<any>();

  const onUploadFile = (event:React.ChangeEvent<any>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result;
        setBaseImg(image);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="workspace">
      <h2 className="title"> Work Area </h2>
      <Cropper baseImg={baseImg} />
      {/* <input type="file" accept="image/*" onChange={onUploadFile} /> */}
    </div>
  );
}

export default Workspace;